<template>
  <app-page ref="page" :toolbar="toolbar" :list="list">
    <template></template>
  </app-page>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: 'ListBeauty',
  data() {
    return {
      toolbar: {
        size: 'small',
        labelWidth: '80px',
        model: {
          startDate: dayjs()
            .startOf('months')
            .format('YYYY-MM-DD'),
          endDate: dayjs()
            .endOf('months')
            .format('YYYY-MM-DD')
        },
        fields: [
          {
            label: '查询日期',
            prop1: 'startDate',
            prop2: 'endDate',
            type: 'daterange',
            valueFormat: 'yyyy-MM-dd',
            format: 'yyyy-MM-dd',
            startPlaceholder: '开始日期',
            endPlaceholder: '结束日期',
            pickerOptions: {
              firstDayOfWeek: 1
            }
          }
        ]
      },
      list: {
        mode: 'all',
        url: '/cosmetology/appointment/statistics',
        method: 'post',
        fields: [
          { label: '项目', prop: 'projectName', width: 400 },
          {
            label: '次数',
            prop: 'number',
            width: 120,
            align: 'right'
          },
          {
            label: '金额',
            prop: 'amount',
            width: 120,
            align: 'right',
            formatter(row) {
              return `￥${row.amount}`;
            }
          }
        ],
        action: false,
        pages: false
      }
    };
  }
};
</script>
