<template>
  <app-tabs v-model="active">
    <el-tab-pane label="美发统计" lazy>
      <list-hairdressing />
    </el-tab-pane>
    <el-tab-pane label="美容统计" lazy>
      <list-beauty />
    </el-tab-pane>
    <el-tab-pane label="美发统计(临时)" lazy>
      <list-hairdressing-temp />
    </el-tab-pane>
    <el-tab-pane label="美容统计(临时)" lazy>
      <list-beauty-temp />
    </el-tab-pane>
  </app-tabs>
</template>

<script>
import listHairdressing from './listHairdressing';
import listBeauty from './listBeauty';
import listHairdressingTemp from './listHairdressingTemp';
import listBeautyTemp from './listBeautyTemp';

export default {
  name: 'stats',
  components: {
    listHairdressing,
    listBeauty,
    listHairdressingTemp,
    listBeautyTemp
  },
  data() {
    return {
      active: '0'
    };
  }
};
</script>
